import React, { useState } from 'react';
import NewsLetter from '../frontend/components/newsletter';
import OurAdvantage from '../frontend/components/our-advantage';
import TrackRecord from '../frontend/components/trackRecord';
import { navigate } from 'gatsby';
import Checkout from '../checkout';

const Financial = () => {
  const [showModal, setModal] = useState(false);
  const [plan, setPlan] = useState({ name: 'Daily Financial Market Timer' });

  const confirmPlan = (details) => {
    setPlan({ ...plan, ...details });
    setModal(true);
  };

  return (
    <div>
      <section className="grey-section-wrapper">
        <div className="container">
          <div className="grey-section-content">
            <figure className="tech-stock-img-wrap">
              <div className="gatsby-image-wrapper">
                <div aria-hidden="true" className="image-guard-large" />
                <img
                  aria-hidden="true"
                  src="../../images/fortucast-financial-overview.jpg"
                  alt=""
                  className="img-class"
                />
              </div>
            </figure>
            <div className="tech-stock-text-wrap">
              <h1 className="tech-stock-text-wrap-h1">{plan.name}</h1>
              <p className="tech-stock-text-wrap-p">
                For short-term traders and moderately active swing futures traders. Markets covered: S&amp;P 500 T-notes
                Dollar Euro Gold Silver Crude Oil Bitcoin Futures.
              </p>
              <a href="#" className="tech-stock-text-wrap-a">
                Try it for one month
                <svg className="tech-stock-text-wrap-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-right"
                  >
                    <line x1={5} y1={12} x2={19} y2={12} />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="tech-stocks-timer-daily-wrap">
        <div className="container">
          <div className="tech-stocks-timer-daily-wrap-title">
            <h2 className="tech-stocks-timer-daily-wrap-title-h2">What's Included in the Financial Timer Daily</h2>
          </div>
          <div className="box-three-wrap">
            <div className="box-three-wrap-report-box">
              <svg className="box-three-wrap-report-box-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-sunrise"
                >
                  <path d="M17 18a5 5 0 0 0-10 0" />
                  <line x1={12} y1={2} x2={12} y2={9} />
                  <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                  <line x1={1} y1={18} x2={3} y2={18} />
                  <line x1={21} y1={18} x2={23} y2={18} />
                  <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                  <line x1={23} y1={22} x2={1} y2={22} />
                  <polyline points="8 6 12 2 16 6" />
                </svg>
              </svg>
              <div className="box-three-wrap-report-box-text">
                <h2 className="box-three-wrap-report-box-text-h2">Morning Report</h2>
                <p className="box-three-wrap-report-box-text-p" />
                <div className="box-three-wrap-report-box-info">
                  {' '}
                  Includes:
                  <p className="box-three-wrap-report-box-info-p">Specific Trading Strategy</p>
                  <p className="box-three-wrap-report-box-info-p">Overnight News Assessments</p>
                  <p className="box-three-wrap-report-box-info-p">Major Calendar Updates</p>
                  <p className="box-three-wrap-report-box-info-p">Pre-opening comments for included markets</p>
                  <p className="box-three-wrap-report-box-info-p">Elliot Wave Cycle Breakdowns</p>
                  <p className="box-three-wrap-report-box-info-p">Big Picture Perspectives and Developments</p>
                  <p className="box-three-wrap-report-box-info-p" />
                </div>
              </div>
            </div>
            <div className="box-three-wrap-report-box">
              <svg className="box-three-wrap-report-box-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-sunset"
                >
                  <path d="M17 18a5 5 0 0 0-10 0" />
                  <line x1={12} y1={9} x2={12} y2={2} />
                  <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                  <line x1={1} y1={18} x2={3} y2={18} />
                  <line x1={21} y1={18} x2={23} y2={18} />
                  <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                  <line x1={23} y1={22} x2={1} y2={22} />
                  <polyline points="16 5 12 9 8 5" />
                </svg>
              </svg>
              <div className="box-three-wrap-report-box-text">
                <h2 className="box-three-wrap-report-box-text-h2">Afternoon Report</h2>
                <p className="box-three-wrap-report-box-text-p" />
                <div className="box-three-wrap-report-box-info">
                  {' '}
                  Includes:
                  <p className="box-three-wrap-report-box-info-p">Specific Trade Recommendations</p>
                  <p className="box-three-wrap-report-box-info-p">Daily Comments and Analysis </p>
                  <p className="box-three-wrap-report-box-info-p">Elliot Wave Cycle Breakdowns</p>
                  <p className="box-three-wrap-report-box-info-p">Weekly Chart Outlook</p>
                  <p className="box-three-wrap-report-box-info-p">Big Picture Perspectives and Developments</p>
                  <p className="box-three-wrap-report-box-info-p" />
                </div>
              </div>
            </div>
            <div className="box-three-wrap-report-box selected">
              <svg className="box-three-wrap-report-box-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-zap"
                >
                  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
                </svg>
              </svg>
              <div className="box-three-wrap-report-box-text">
                <h2 className="box-three-wrap-report-box-text-h2">Intraday Updates</h2>
                <p className="box-three-wrap-report-box-text-p" />
                <div className="box-three-wrap-report-box-info">
                  {' '}
                  Includes:
                  <p className="box-three-wrap-report-box-info-p">Day and Swing Trading Strategy Updates</p>
                  <p className="box-three-wrap-report-box-info-p">Quick Support and Resistance Pattern Analysis </p>
                  <p className="box-three-wrap-report-box-info-p">Realtime Delivery</p>
                  <p className="box-three-wrap-report-box-info-p" />
                </div>
              </div>
            </div>
          </div>
          <div className="tech-stocks-timer-daily-wrap-title">
            <div className="border-top-text">
              <p className="border-top-text-p">
                Daily volume of reports and updates may increase or decrease to align with market volataility
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="grey-section-wrapper">
        <div className="container">
          <h2 className="grey-section-h2">Futures Markets Covered</h2>
          <p className="grey-section-p" />
          <div className="stock-wrap">
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" className="stock-box-img-guard" />
                  <img aria-hidden="true" src="../../images/fortucast-fin-sp-500.jpg" alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  S&amp;P500
                  {/* */}
                </p>
              </div>
            </div>
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" style={{ width: '100%', paddingBottom: '66.5%' }} />
                  <img aria-hidden="true" src="../../images/fortucast-fin-gold.jpg" alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  Gold
                  {/* */}
                </p>
              </div>
            </div>
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" style={{ width: '100%', paddingBottom: '56%' }} />
                  <img aria-hidden="true" src="../../images/fortucast-fin-silver.jpg" alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  Silver
                  {/* */}
                </p>
              </div>
            </div>
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" style={{ width: '100%', paddingBottom: '80%' }} />
                  <img aria-hidden="true" src="../../images/fortucast-fin-crude-oil.jpg" alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  Crude Oil
                  {/* */}
                </p>
              </div>
            </div>
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" style={{ width: '100%', paddingBottom: '113%' }} />
                  <img aria-hidden="true" src="../../images/fortucast-fin-t-notes.jpg " alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  T-Notes
                  {/* */}
                </p>
              </div>
            </div>
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" style={{ width: '100%', paddingBottom: '66.5%' }} />
                  <img aria-hidden="true" src="../../images/fortucast-fin-dollar.jpg" alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  Dollar
                  {/* */}
                </p>
              </div>
            </div>
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" style={{ width: '100%', paddingBottom: '66.5%' }} />
                  <img aria-hidden="true" src="../../images/fortucast-fin-euro.jpg" alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  Dollar
                  {/* */}
                </p>
              </div>
            </div>
            <div className="stock-wrap-box">
              <figure className="stock-wrap-box-fig">
                <div className=" gatsby-image-wrapper">
                  <div aria-hidden="true" style={{ width: '100%', paddingBottom: '66.5%' }} />
                  <img aria-hidden="true" src="../../images/fortucast-fin-bitcoin.jpg" alt="" className="img-class" />
                </div>
              </figure>
              <div className="stock-box-text">
                <p className="stock-box-text-title">
                  Bitcoin
                  {/* */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tech-stocks-timer-daily-wrap" id="plans">
        <div className="container">
          <div className="tech-stocks-timer-daily-wrap-title">
            <h2 className="tech-stocks-timer-daily-wrap-title-h2">Subscription Plan</h2>
            <p className="subscription-plan-p">Choose the payment plan that’s right for you</p>
          </div>
          <div className="top-red-border-wrap">
            <h5 className="top-red-border-wrap-h5">Limited Time Only</h5>
            <p className="top-red-border-wrap-p">
              <svg className="top-red-border-wrap-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-zap"
                >
                  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
                </svg>
              </svg>
              {/* */}Intraday Updates Included Free
            </p>
          </div>
          <div className="subscription-plan-box-wrap">
            <div className="subscription-plan-box-wrap-left">
              <h3 className="subscription-plan-box-wrap-h3">Annual</h3>
              <p className="border-top-text-p">Access to all daily, weekly market updates. $1596 every 12 months.</p>
              <div className="subscription-plan-box-wrap-price">
                <p className="subscription-plan-box-wrap-price-large">$133</p>
                <p className="subscription-plan-box-wrap-per-month">per month</p>
              </div>
              <button
                className="subscription-plan-box-select-plan"
                onClick={() =>
                  confirmPlan({
                    price_id: 'price_1HShYkF0TpJ7Nj351eV2MT1S',
                    price: '1596',
                    type: 'Annually',
                    product_id: 'zyVDEytLZLzdG4HxSfeV',
                  })
                }
              >
                Select Plan
              </button>
            </div>
            <div className="subscription-plan-box-wrap-right">
              <h3 className="subscription-plan-box-wrap-h3">Quarterly</h3>
              <p className="border-top-text-p">Access to all daily, weekly market updates. $597 every 3 months.</p>
              <div className="subscription-plan-box-wrap-price">
                <p className="subscription-plan-box-wrap-price-large">$199</p>
                <p className="subscription-plan-box-wrap-per-month">per month</p>
              </div>
              <button
                className="subscription-plan-box-select-plan-outline"
                onClick={() =>
                  confirmPlan({
                    price_id: 'price_1HShYkF0TpJ7Nj35smxNtF0l',
                    price: '597',
                    type: 'Quarterly',
                    product_id: 'zyVDEytLZLzdG4HxSfeV',
                  })
                }
              >
                Select Plan
              </button>
            </div>
          </div>
        </div>
      </section>
      <OurAdvantage />
      <TrackRecord />
      <NewsLetter />
      <Checkout
        isOpen={showModal}
        close={() => setModal(false)}
        plan_name={plan.name}
        price={plan.price}
        plan_type={plan.type}
        product_id={plan.product_id}
        price_id={plan.price_id}
        plan_url={'financial'}
      />
    </div>
  );
};

export default Financial;
